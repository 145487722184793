import React from 'react';
import Layout from '../components/layout';
import JSONDATA from "../data/translations.json"

const About = () => {
  const LANGUAGE = process.env.GATSBY_LANGUAGE;

  return (
    <Layout>
      <div className="container mx-auto">
        <div className="mx-8">
          <h1>{LANGUAGE ? JSONDATA[LANGUAGE].about : ""}</h1>
          <div dangerouslySetInnerHTML={{__html: LANGUAGE ? JSONDATA[LANGUAGE].aboutTextHtml : ""}}></div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
