import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { ICategoryViewProps } from '../data/categories';
import { ISolution } from '../data/solutions';
import JSONDATA from "../data/translations.json"

export const CategoryView = (props: ICategoryViewProps) => {
  const LANGUAGE = process.env.GATSBY_LANGUAGE;

  const shuffleArray = (solutions: ISolution[]): ISolution[] => {
    for (let i = solutions.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [solutions[i], solutions[j]] = [solutions[j], solutions[i]];
    }
    return solutions;
  };

  return (
    <div className="solutions-wrapper container mx-auto max-w-7xl">
      <h1 className="mx-8 mt-8">{props.categoryInfo.lead}</h1>
      <ul className="mx-8">
        {shuffleArray(props.solutions).map((x) => (
          <li
            key={x.node.solutionId}
            id={x.node.solutionId + 'solution'}
            className="shadow-md rounded-md my-10 bg-gray-100 flex flex-col border-solid border border-gray-300 max-w-6xl accordion-closed"
          >
            <div
              className="flex cursor-pointer relative"
              onClick={() => {
                document.getElementById(x.node.solutionId + 'description')?.classList.toggle('description-hidden');
                document.getElementById(x.node.solutionId + 'solution')?.classList.toggle('accordion-open');
              }
              }
            >
              {x.node.image.src ?
                <GatsbyImage image={getImage(x.node.image.src) as IGatsbyImageData} alt={x.node.image.name} className="m-2 basis-24 shrink-0 h-24 w-24 object-scale-down md:h-32 md:basis-32 border border-solid border-gray-200 bg-white rounded-tl" />
                :
                <div className="m-2 basis-24 shrink-0 h-24 w-24 object-scale-down md:h-32 md:basis-32 border border-solid border-gray-200 bg-gray-300 rounded-tl"></div>
              }
              <div>
                <h2 className="break-words max-w-full">{x.node.title}</h2>
                <h3 style={{ color: '#777' }} className="mb-10 mr-2">
                  {x.node.productName}
                </h3>
                <span className='accordion-button'>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 10 100 100"
                  >
                    <g transform="rotate(135, 50, 50)">
                      <path d="M 30 30 H 70 V 40 H 30 L 30 30" />
                      <path d="M 70 30 V 70 H 60 V 30 L 70 30" />
                    </g>
                  </svg>
                </span>
              </div>
            </div>
            <div
              className="bg-gray-200 rounded-b-md description-hidden"
              id={x.node.solutionId + 'description'}
            >
              <div>
                <p
                  className="text-gray-900 m-4"
                  dangerouslySetInnerHTML={{ __html: x.node.description }}
                />
              </div>
              <div className="bg-gray-200 flex justify-end py-3 px-1 flex-wrap">
                <a
                  href={x.node.link}
                  className="inline-block bg-secondary text-white px-4 py-2 m-1 rounded"
                  target="_blank"
                  rel="noreferrer"
                >
                  {LANGUAGE ? JSONDATA[LANGUAGE].readMoreAt : ""} {x.node.company}
                </a>
                {x.node.additionalLinks?.map((y) => {
                  return (
                    <a
                      href={y.url}
                      className="inline-block bg-secondary text-white px-4 py-2 m-1 rounded"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {y.title}
                    </a>
                  )
                })
                }
                {x.node.referenceCases?.map((y) => {
                  return (
                    <a
                      href={y}
                      className="inline-block bg-secondary text-white px-4 py-2 m-1 rounded"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {LANGUAGE ? JSONDATA[LANGUAGE].referenceCases : ""}
                    </a>
                  );
                })}
                {x.node.video?.map((y) => {
                  return (
                    <a
                      href={y}
                      className="inline-block bg-secondary text-white px-4 py-2 m-1 rounded"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Video
                    </a>
                  );
                })
                }
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
