import * as React from "react"
import { useState } from "react"
import CategoryList from "../components/category-list"
import "../styles/global.css";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import JSONDATA from "../data/translations.json"

export const query = graphql`
  query IndexPageQuery {
    allCategoriesJson {
      edges {
        node {
          categoryId
          name
          lead
          keywords
          slug
          image {
            src {
            childImageSharp {
              gatsbyImageData(width: 80)
            }
          }
          name
          }
        }
      }
    }
    allSolutionsJson {
      edges {
        node {
          categories
          company
          productName
        }
      }
    }
  }
  `

// markup
const IndexPage = ({ data }) => {
  const LANGUAGE = process.env.GATSBY_LANGUAGE;
  const categoryData = data.allCategoriesJson.edges;
  const solutionData = data.allSolutionsJson.edges;
  const [searchString, setSearchString] = useState<string>('');

  const onSearchInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchString(e.currentTarget.value.toLowerCase());
  };

  const onEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.currentTarget.blur();
    }
  };

  return (
    <Layout>
      <div className="container mx-auto">
        <div>
          <h1 className="mx-8 mt-8">
            {LANGUAGE ? JSONDATA[LANGUAGE].metaData.header : ""}
          </h1>
          <p className="mx-8">
          {LANGUAGE ? JSONDATA[LANGUAGE].metaData.aboutLong : ""}
          </p>
        </div>
        <div
          style={{ display: 'flex', justifyContent: 'center', margin: '3em 0' }}
        >
          <input
            type="text"
            placeholder={LANGUAGE ? JSONDATA[LANGUAGE].searchFieldPlaceholder : ""}
            className="text-xl shadow-md rounded-md h-16 w-96 max-w-3/4 px-4 focus:outline-none focus:ring focus:border-blue-300 border border-solid border-gray-300"
            onChange={onSearchInputChange}
            onKeyUp={onEnter}
          />
        </div>
        <CategoryList
          data={categoryData.filter((x) => {
            const keywords = x.node.keywords.some((y) =>
              y.toLowerCase().includes(searchString.toLowerCase()),
            );
            const solutions = solutionData.find(
              (z) =>
                z.node.categories.find((cat) => cat === x.node.categoryId) &&
                (z.node.company.toLowerCase().includes(searchString.toLowerCase()) ||
                  z.node.productName
                    .toLowerCase()
                    .includes(searchString.toLowerCase())),
            );
            return keywords || solutions;
          })}
        />
      </div>
    </Layout>
  )
}

export default IndexPage
