import React from 'react';
import Layout from '../components/layout';
import JSONDATA from "../data/translations.json"

const Join = () => {
  const LANGUAGE = process.env.GATSBY_LANGUAGE;

  return (
    <Layout>
      <div className="container mx-auto">
        <div className="mx-8">
          <h1>{LANGUAGE ? JSONDATA[LANGUAGE].wantToPublishSolutionHeader : ""}</h1>
          <p>
          {LANGUAGE ? JSONDATA[LANGUAGE].wantToPublishSolutionText : ""}
          </p>
          <p>
          {LANGUAGE ? JSONDATA[LANGUAGE].contact : ""}
            <br />
            Magnus Larsson
            <br />
            Telefon: 0709-550 450
            <br />
            E-post:{' '}
            <a href="mailto:info@branschlosningar.se">
            info@branschlosningar.se
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Join;