import React from 'react';
import Layout from '../components/layout';
import JSONDATA from "../data/translations.json"

const Contact = () => {
  const LANGUAGE = process.env.GATSBY_LANGUAGE;

  return (
    <Layout>
      <div className="container mx-auto">
        <div className="mx-8">
          <h1>{LANGUAGE ? JSONDATA[LANGUAGE].contactHeader : ""}</h1>
          <p>
            {LANGUAGE ? JSONDATA[LANGUAGE].contactText : ""}
          </p>
          <p className='mt-6'>
            Partner Development Center Sweden AB
            <br />
            c/o Interventor
            <br />
            Tulegatan 20
            <br />
            113 53 Stockholm
            <br />
            Telefon: 0709-550 450 <br />
            E-post: <a href="mailto:info@branschlosningar.se">info@branschlosningar.se</a>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
